import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

import Seo from "../components/Seo"
import Hero from '../components/Hero'
import Sidebar from '../components/Sidebar'
import Widget from '../components/Widget'
import SignUpSection from '../components/SignUpSection'
import SidebarWidget from "../components/SidebarWidget"
import replaceAll from "../utils/replaceAll"

// SVG Icon used in the article links
const linkIcon = () => (
  `<svg
    width="12" 
    height="12" 
    viewBox="0 0 12 12" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0v12h12V9h-1.5v1.5h-9v-9H3V0H0zm6 0l2.25 2.25L4.5 6 6 7.5l3.75-3.75L12 6V0H6z" fill="#E7513D"/>
  </svg>`
)

const displayWidgets = (widgets) => (
  widgets.map( widget => (
    <Widget
      key={ widget.widget_link_url }
      headline={ widget.widget_title } 
      text={ widget.widget_content } 
      label={ widget.widget_link_label }  
      link={ widget.widget_link_url }  
    />
  ))
)

const replaceHeading = (article, heading) => {
  let newArticle = '';
  newArticle = article.replace('<h2>', `<h2 id="${heading.value}">`);
  return newArticle;
}

export default function about({ data }) {

  // = = = = = = = = = = = = =
  // Forestry Data
  const aboutData = data.markdownRemark.frontmatter;
  const headings = data.markdownRemark.headings;
  let article = data.markdownRemark.html;
  const showWidgets = data.markdownRemark.frontmatter.would_you_like_to_show_widgets_bellow_the_main_article;
  const widgets = data.markdownRemark.frontmatter.bellow_article_widgets;

  // = = = = = = = = = = = = =
  // Utils

  // Add id to each Heading with title as a value
  headings.forEach( heading => {
    article = replaceHeading(article, heading);
  });

  // Add icon to each link of the article
  const replacedArticle = replaceAll(article, '</a>', `${linkIcon()}</a>`);

  return(
    <>
      <Seo title = { aboutData.page_title } />

      <Hero 
        headline = { aboutData.hero_headline }
        intro = { aboutData.hero_intro }
        image = { aboutData.hero_background_image }
        page="page"
        size="medium"
      >
        <StaticImage 
          className="hero__picture"
          src="../../static/media/about-hero.jpg" 
          alt=""
          placeholder="blurred"
        />
      </Hero>

      <div className="page-content">
        <div className="container">
          <div className="row">

            <div className="col-md-3">
              <Sidebar 
                headings = { data.markdownRemark.headings } 
                links = { aboutData.sidebar_links }>
                  
                  {/* <SidebarWidget 
                    headline="Become a worker voter"
                    linkLabel="Find my organisation"
                    link="/voter-registration"
                  >
                    <StaticImage 
                      className="sidebar__widget__image" 
                      src="../images/sidebar-widget.jpg" 
                      alt=""
                    />
                  </SidebarWidget> */}

                  {/* <SidebarWidget 
                    headline="How to choose your voters"
                    linkLabel="Step-by-step guide"
                    link="/registration-guide"
                  >
                    <StaticImage 
                      className="sidebar__widget__image" 
                      src="../../static/media/sidebar-widget-2.jpg" 
                      alt=""
                    /> 
                  </SidebarWidget> */}

                  <SidebarWidget 
                    headline="Apply for a postal vote"
                    linkLabel="Must be received by 5pm on 9th March"
                    link="/postal"
                  >
                    <StaticImage 
                      className="sidebar__widget__image" 
                      src="../images/sidebar-widget-vote.jpg" 
                      alt=""
                    />
                  </SidebarWidget>

                  <SidebarWidget 
                    headline="Stand for the City"
                    linkLabel="Become a Candidate"
                    link="/become-a-councillor"
                  >
                    <StaticImage 
                      className="sidebar__widget__image" 
                      src="../images/sidebar-widget-councillor.jpg" 
                      alt=""
                    /> 
                  </SidebarWidget>

              </Sidebar>
            </div>

            <div className="col-md-9">
              <article dangerouslySetInnerHTML={{__html: replacedArticle }} />
              
              {
                showWidgets === true
                  ? displayWidgets(widgets)
                  :''
              }
              
            </div>

          </div>
        </div>
      </div>

      <SignUpSection />
    </>
  )
}

// = = = = = = = = = = = = =
// GraphQL
export const query = graphql`
  query KeyDatesQuery {
    markdownRemark(frontmatter: {page_title: {eq: "Key Dates"}}) {
      frontmatter {
        page_title
        hero_headline
        hero_intro
        sidebar_links {
          link_label
          link_url
        }
        would_you_like_to_show_widgets_bellow_the_main_article
        bellow_article_widgets {
          widget_title
          widget_content
          widget_link_label
          widget_link_url
        }
      }
      headings(depth: h2) {
        value
      }
      html
    }
  }
`;